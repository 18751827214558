<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-card class="elevation-2 mt-10">
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Inloggen</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form @submit.prevent="submit">
              <v-text-field
                label="Email"
                name="username"
                autofocus
                autocomplete="username"
                prepend-icon="person"
                type="text"
                class="username"
                v-model="form.username"
              ></v-text-field>
              <v-text-field
                id="password"
                label="Wachtwoord"
                autocomplete="current-password"
                name="password"
                prepend-icon="lock"
                type="password"
                v-model="form.password"
              ></v-text-field>
              <v-btn color="primary" type="submit"> Inloggen</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-layout align-center justify-center>
      <v-flex md4 sm8 xs12></v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ProfileApi from '@/api/ProfileApi'
import SessionApi from '@/api/SessionApi'
import { createFlashMessage } from '@/stores/utils'

export default {
  data () {
    return {
      form: {
        username: null,
        password: null,
      }
    }
  },
  methods: {
    submit () {
      SessionApi.login(this.form).then(resp => {
        if (!resp || !resp.jwt) {
          const flash = createFlashMessage({ type: 'warning', message: 'Authenticatie mislukt' })
          this.$store.dispatch('globalStore/addFlashMessage', flash)
          return Promise.reject("Authenticatie mislukt")
        }
        return Promise.resolve(resp.jwt)
      }).then(token => {
        return this.$store.dispatch('globalStore/setToken', token)
      }).then(() => {
        this.profile()
      }).catch(() => {
        const flash = createFlashMessage({ type: 'error', message: 'Fout bij het inloggen, probeer opnieuw' })
        this.$store.dispatch('globalStore/addFlashMessage', flash)
      })
    },
    profile () {
      ProfileApi.me().then(user => {
        this.$store.dispatch('globalStore/setUser', user)
        // forward user to home
        this.$router.push({ name: 'dashboard' })
      }).catch(() => {
        const flash = createFlashMessage({ type: 'error', message: 'Fout bij het laden' })
        this.$store.dispatch('globalStore/addFlashMessage', flash)
      })
    }
  },
}
</script>
<style lang="scss">
.username input {
  text-transform: lowercase;
}
</style>
