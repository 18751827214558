import { handleApiErrors } from '@/api/apiErrors'

const SessionApi = {
  login (credentials) {
    return fetch('/api/sign-in', {
      body: JSON.stringify(credentials),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
    }).then(handleApiErrors)
      .then(response => response.json())
  },

  register (user) {
    return fetch('/api/sign-up', {
      body: JSON.stringify(user),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
    }).then(handleApiErrors)
      .then((resp) => resp.json())
  },
}

export default SessionApi
